




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReviewEntryParams } from './consts';
import { ReviewProviders } from '@/models/review';

@Component({})
export default class ReviewsEntry extends Vue {
  @Prop({ required: true })
  profilePhoto!: string;
  @Prop({ required: true })
  name!: string;
  @Prop({ required: true })
  createdAt!: string;
  @Prop({ default: '' })
  comment!: string;
  @Prop({ required: true })
  rating!: number;
  @Prop({ required: true })
  provider!: keyof typeof ReviewProviders;
  photoError = false;

  public availableProviders: Record<ReviewProviders, ReviewProviders> = ReviewProviders;

  public maximumRating = ReviewEntryParams.MAX_RATING;
  public maximumReviewLength = ReviewEntryParams.MAX_REVIEW_CHARACTERS;

  get formattedComment(): string {
    if (!this.comment) {
      return `${this.name} udělil hodnocení ${this.rating} hvězdiček`;
    }
    return this.comment?.length > this.maximumReviewLength ? this.ellipsisComment : this.comment;
  }

  get formattedDate(): string {
    const date = new Date(this.createdAt);
    return date.toLocaleDateString();
  }

  get ellipsisComment(): string {
    return this.comment.slice(0, this.maximumReviewLength) + '...';
  }

  get photoSource(): string {
    return this.photoError ? require('@/assets/img/default-profile.svg') : this.profilePhoto;
  }
}
