























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class GoogleReviewsBadge extends Vue {
  @Prop({ required: false, default: 4.5 }) rating!: number;
  maximumRating = 5;

  get ceilRating() {
    return Math.ceil(this.rating);
  }
}
