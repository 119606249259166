












import { Component, Vue } from 'vue-property-decorator';
import { XIcon } from 'vue-feather-icons';

@Component({
  components: {
    XIcon
  }
})
export default class Modal extends Vue {
  public show() {
    this.$modal.show('mobile-search-modal');
  }
  public hide() {
    document.body.style.overflow = '';
    this.$modal.hide('mobile-search-modal');
  }
}
