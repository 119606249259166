<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.16075 6.49408C7.83531 6.81951 7.83531 7.34715 8.16075 7.67259L10.4882 10L8.16075 12.3274C7.83531 12.6528 7.83531 13.1805 8.16075 13.5059C8.48618 13.8314 9.01382 13.8314 9.33926 13.5059L12.2559 10.5893C12.5814 10.2638 12.5814 9.73618 12.2559 9.41074L9.33926 6.49408C9.01382 6.16864 8.48618 6.16864 8.16075 6.49408Z"
      fill="#23262F"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon'
};
</script>
