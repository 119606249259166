<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_166_3539)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3748 14.2823C18.1391 14.0468 17.2798 13.1865 16.58 12.4873C15.8802 11.7881 14.7458 11.7881 14.0461 12.4873C13.3464 13.1865 12.9711 13.5627 12.7856 13.7481C11.9385 14.5948 9.95846 13.9013 8.02786 11.9712C6.09726 10.0411 5.38979 8.07734 6.25216 7.21444C6.39374 7.07276 6.81296 6.65394 7.51266 5.95424C8.21236 5.25454 8.21236 4.12024 7.51266 3.42054C6.81296 2.72084 6.55166 2.45864 5.71806 1.62514C4.88446 0.791637 3.53282 0.791605 2.69926 1.62514C2.5357 1.78869 1.95954 2.36536 1.78866 2.53624C-0.141773 4.46661 1.53356 9.25934 6.13716 13.8633C10.7408 18.4673 15.5338 20.1416 17.4643 18.2114C17.5666 18.1091 18.2056 17.4696 18.3749 17.3003C19.2084 16.4668 19.2084 15.1153 18.3748 14.2823Z"
        stroke="#23262F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_166_3539">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon'
};
</script>
