






















import { Component, Vue } from 'vue-property-decorator';
import Banner from '@/components/ui/banner/index.vue';
import { BannerContent } from '@/components/ui/banner/consts';
//@ts-ignore
import { yellow, black, grey } from '@/styles/interface/_colors.module.scss';
import { ChevronRightIcon } from 'vue-feather-icons';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';
import moment from 'moment';

@Component({
  components: {
    Banner,
    ChevronRightIcon
  }
})
export default class WhyKakadoo extends Vue {
  public bannerContents: BannerContent[] = [
    // {
    //   heading: 'Vyražte na dovolenou s naším mikrobusem se 40% slevou.',
    //   description: 'Zobrazit dostupnost a ceny >',
    //   backgroundColor: yellow,
    //   link: {
    //     path: `/mikrobusy?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(DEFAULT_DATE_FORMAT)}`
    //   },
    //   image: require('../../assets/img/black-van.png')
    // },
    {
      heading: 'Vyzvednutí a vrácení kdykoliv bez příplatku, jak se vám hodí',
      description: 'Jak to funguje >',
      backgroundColor: yellow,
      link: {
        uri: '/jak-to-funguje#vyzvednuti'
      },
      icon: {
        path: require('../../assets/icons/car-outline.svg'),
        color: yellow,
        backgroundColor: black
      }
    },
    {
      heading: 'Sleva 200 Kč s kódem',
      largeDescription: 'MAJ200',
      largeDescriptionMobile: true,
      fontColor: yellow,
      backgroundImage: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/banner-maj-200.webp',
      link: {
        path: `/pujcovna?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(DEFAULT_DATE_FORMAT)}`
      }
    },
    {
      heading: 'Nonstop podpora',
      largeDescription: '776 500 507',
      largeDescriptionMobile: true,
      backgroundColor: black,
      fontColor: yellow,
      link: {
        uri: 'tel:00420776500507'
      },
      icon: {
        path: require('../../assets/icons/phone-24-outline.svg'),
        color: black,
        backgroundColor: yellow
      }
    },
    {
      heading: 'Věrnostní program pro stálé zákazníky',
      description: 'Podívejte se na naše ceny >',
      backgroundColor: grey,
      link: {
        path: '/cenik'
      },
      icon: {
        path: require('../../assets/icons/coupon-outline.svg'),
        color: black,
        backgroundColor: yellow
      }
    }
  ];
  handleButtonClick() {
    this.$router.push('/jak-to-funguje');
  }
}
