import axios from 'axios';
import { ParkingWithoutArea } from '@/models/parking';

class ParkingLocationsService {
  public async getParkingLocations(): Promise<ParkingWithoutArea[]> {
    const response = await axios.get<{
      parkingLocations: ParkingWithoutArea[];
    }>('/parking/filter');
    return response.data.parkingLocations;
  }
}

export default new ParkingLocationsService();
