





















































import { Component, Vue } from 'vue-property-decorator';
import { AlertCircleIcon, ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons';
import VanCardNew from '@/components/vans/vanCardNew.vue';
import { Car, CarAvailability } from '@/store/types';
import carService from '@/services/cars.service';
import moment from 'moment';
import { RENTAL } from '@/models/carType';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

@Component({
  components: {
    AlertCircleIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    VanCardNew
  }
})
export default class TodaysAvailableCars extends Vue {
  public cars: CarAvailability[] | undefined = [] as CarAvailability[];
  public daysCount = 1;
  public offset = 0;
  public cardWidth = 0;
  public currentIndex = 0;
  public visibleCards = 3;
  public gapSize = 40; // Needs to match the base (biggest/desktop) gap in CSS (40px = size(5))
  private startX = 0;
  private currentOffset = 0;
  private threshold = 30; // Minimum distance (in pixels) for a swipe
  private mobileWidthCoeff = 0.9; // [x * 100 in %] width of van cards for mobile devices
  public cardBorderAdjustment = 2; // 1px eaxh side (2px total)
  public failed = false;
  public loading = false;
  private swipeBoostCoefficient = 3; // Allow for a shorter swipe. TODO: Find a perfect value

  public args = {
    start: moment().format(DEFAULT_DATE_FORMAT),
    end: moment().format(DEFAULT_DATE_FORMAT),
    filters: {
      vehicleTypes: [],
      city: '',
      parkingDescriptions: []
    },
    orderBy: {
      prop: null,
      order: null
    }
  };

  get maximumIndex() {
    return Math.max(0, (this.cars?.length || 0) - this.visibleCards);
  }

  async mounted() {
    this.calculateCardWidth();
    window.addEventListener('resize', this.adjustGapSize);
    window.addEventListener('resize', this.calculateCardWidth);
    this.adjustGapSize();
    this.cars = await this.getAvailableCars();
    const carousel = this.$el.querySelector('.carousel-container');
    if (carousel) {
      carousel.addEventListener('touchstart', this.handleTouchStart as EventListener, { passive: true });
      carousel.addEventListener('touchmove', this.handleTouchMove as EventListener, { passive: false });
      carousel.addEventListener('touchend', this.handleTouchEnd as EventListener, { passive: true });
    }
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustGapSize);
    window.removeEventListener('resize', this.calculateCardWidth);
    const carousel = this.$el.querySelector('.carousel-container');
    if (carousel) {
      carousel.removeEventListener('touchstart', this.handleTouchStart as EventListener);
      carousel.removeEventListener('touchmove', this.handleTouchMove as EventListener);
      carousel.removeEventListener('touchend', this.handleTouchEnd as EventListener);
    }
  }

  public adjustGapSize() {
    const screenWidth = window.innerWidth;
    this.gapSize = screenWidth <= 640 ? 10 : 40; // Matches the CSS media queries
  }

  // ----- TOUCH EVENT HANDLERS
  handleTouchStart(evt: TouchEvent) {
    this.startX = evt.touches[0].clientX;
    this.currentOffset = this.offset; // Capture the current offset at touch start
  }

  handleTouchMove(evt: TouchEvent) {
    const touchX = evt.touches[0].clientX;
    const distance = touchX - this.startX;
    this.offset = this.currentOffset + distance;
    evt.preventDefault(); // Prevent scrolling the page
  }

  handleTouchEnd(evt: TouchEvent) {
    if (!this.cars) {
      return;
    }
    // Calculate the total swipe distance
    const endX = evt.changedTouches[0].clientX;
    const swipeDistance = endX - this.startX;

    // Calculate the expected offset based on the swipe
    const expectedOffset = this.currentOffset + swipeDistance * this.swipeBoostCoefficient;

    // Determine the nearest card index based on the expected offset
    // Note: Math.round() ensures we snap to the nearest card
    let cardIndex = Math.round(Math.abs(expectedOffset) / (this.cardWidth + this.gapSize));

    // Ensure the card index is within the bounds
    cardIndex = Math.max(0, Math.min(cardIndex, this.maximumIndex));

    // Update currentIndex and offset to align with the nearest card
    this.currentIndex = cardIndex;
    this.adjustOffset();

    // TODO: Optionally, animate the snapping effect, too tired now
    // this.animateSnap();
  }
  // ----- END OF TOUCH EVENT HANDLERS

  calculateCardWidth() {
    this.adjustGapSize();
    const screenWidth = window.innerWidth;
    this.visibleCards = screenWidth <= 640 ? 1 : screenWidth <= 1000 ? 2 : 3;
    const carouselContainer = this.$el.querySelector('.carousel-container');
    if (carouselContainer) {
      const carouselWidth = carouselContainer.clientWidth;
      const oldCardWidth = this.cardWidth;
      if (screenWidth <= 640) {
        // Make the card x % smaller than the container on small (mobile) screens
        this.cardWidth = (carouselWidth - this.gapSize) * this.mobileWidthCoeff;
      } else {
        this.cardWidth =
          (carouselWidth - this.gapSize * (this.visibleCards - 1)) / this.visibleCards - this.cardBorderAdjustment;
      }

      if (oldCardWidth !== 0) {
        // If this is not the initial calculation, keep the leftmost visible item in view
        this.offset = (this.offset * (this.cardWidth + this.gapSize)) / (oldCardWidth + this.gapSize);
      }
    }
  }

  public adjustOffset() {
    if (this.visibleCards === 1 && this.cars && this.cars.length > 0) {
      const carouselWidth = this.$el.querySelector('.carousel-container')?.clientWidth;
      const carsWidth = this.$el.querySelector('.cars-container')?.clientWidth;
      const cardWidthWithGap = this.cardWidth + this.gapSize; // Adjusted card width including gap
      if (!carouselWidth || !carsWidth) {
        return;
      }
      if (this.currentIndex === 0) {
        // Align the first card to the left
        this.offset = 0;
      } else if (this.currentIndex === this.cars.length - 1) {
        // For the last card, calculate the offset so it aligns to the right
        this.offset = -(carsWidth - carouselWidth);
      } else {
        // For middle cards, center the card with a peek on both sides if possible
        const peekOffset = (carouselWidth - cardWidthWithGap + this.gapSize) / 2; // Center card and adjust for peek
        this.offset = -(cardWidthWithGap * this.currentIndex) + peekOffset;
        // Clamp the offset to ensure it doesn't exceed the total width of all cards
        this.offset = Math.max(this.offset, -(carsWidth - carouselWidth));
      }
    } else {
      // For non-mobile views or when more than 1 card is visible
      this.offset = -(this.cardWidth + this.gapSize) * this.currentIndex;
    }
  }

  public slideLeft() {
    if (!this.cars) {
      return;
    }
    if (this.cars.length > 0) {
      if (this.currentIndex === 0) {
        // If at the first item, wrap around to the last
        this.currentIndex = this.maximumIndex;
        this.offset = -(this.cardWidth + this.gapSize) * this.maximumIndex;
      } else {
        this.currentIndex--;
        this.offset += this.cardWidth + this.gapSize;
      }
    }
  }

  public slideRight() {
    if (!this.cars) {
      return;
    }
    if (this.cars.length > 0) {
      if (this.currentIndex >= this.maximumIndex) {
        // If at the last item, wrap to the first
        this.currentIndex = 0;
        this.offset = 0;
      } else {
        this.currentIndex++;
        this.offset -= this.cardWidth + this.gapSize;
      }
    }
  }

  navigateToCarOrRental(car?: Car) {
    this.$router.push({
      path: car ? `${RENTAL}/${car.urlSlug}` : RENTAL,
      query: { from: moment().format(DEFAULT_DATE_FORMAT), to: moment().format(DEFAULT_DATE_FORMAT) }
    });
  }

  public async getAvailableCars() {
    this.loading = true;
    this.failed = false;
    try {
      const carsAvailabilityArray = await carService.searchCar(this.args);

      const availableCars = carsAvailabilityArray.filter((cwa) => cwa.isAvailable);
      const unavailableCars = carsAvailabilityArray.filter((cwa) => !cwa.isAvailable);

      const numberOfCarsAvailable = availableCars.length;

      // If there are enough available cars to match or exceed the number of visibleCards, return all available cars
      if (numberOfCarsAvailable >= this.visibleCards) {
        return availableCars;
      } else {
        // Combine available cars with enough unavailable cars to match the visibleCards count
        return [...availableCars, ...unavailableCars.slice(0, this.visibleCards - numberOfCarsAvailable)];
      }
    } catch (e) {
      this.failed = true;
    } finally {
      this.loading = false;
    }
  }
}
