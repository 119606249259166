export type GetAllReviews = {
  [Provider in ReviewProviders]?: PlaceReviews;
};

export interface PlaceReviews {
  averageRating: number;
  reviews: Review[];
}
export enum ReviewProviders {
  google = 'google',
  seznam = 'seznam'
}

export interface ReviewWithProvider extends Review {
  provider: keyof typeof ReviewProviders;
}
export interface Review {
  author: ReviewAuthor;
  rating: ReviewRating;
  comment?: string;
  createdAt: Date;
}
export interface ReviewAuthor {
  name: string;
  profilePhotoUrl: string;
}
export type ReviewRating = 1 | 2 | 3 | 4 | 5;
