<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8393 6.49408C12.1647 6.81951 12.1647 7.34715 11.8393 7.67259L9.51184 10L11.8393 12.3274C12.1647 12.6528 12.1647 13.1805 11.8393 13.5059C11.5138 13.8314 10.9862 13.8314 10.6607 13.5059L7.74408 10.5893C7.41864 10.2638 7.41864 9.73618 7.74408 9.41074L10.6607 6.49408C10.9862 6.16864 11.5138 6.16864 11.8393 6.49408Z"
      fill="#23262F"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeftIcon'
};
</script>
