

































































import { Component, Vue } from 'vue-property-decorator';
import { AlertCircleIcon } from 'vue-feather-icons';
import SecurityCheckIcon from '@/assets/icons/SecurityCheckIcon.vue';

@Component({
  components: {
    AlertCircleIcon,
    SecurityCheckIcon
  }
})
export default class HowItWorksInFiveSteps extends Vue {}
