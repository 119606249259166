








































import { Component, Vue } from 'vue-property-decorator';
import ClientOnly from 'vue-client-only';
import { SearchIcon } from 'vue-feather-icons';
import Button from '../ui/Button.vue';
import NewVanFilter, { NewVanFilterOptions } from '../vans/newVanFilter.vue';
import FieldWrapper from './../ui/form/fieldWrapper.vue';
import { createQueryFromFilterOptions } from './../../components/vans/utils/filterToQuery';
import Modal from '../ui/Modal.vue';

@Component({
  components: {
    Button,
    NewVanFilter,
    SearchIcon,
    Modal,
    FieldWrapper,
    ClientOnly
  }
})
export default class HomepageFilter extends Vue {
  public width = 0;
  public screenMdMin = 1024;
  public selectedFilter: NewVanFilterOptions = {
    dates: { startDate: null, endDate: null },
    types: [],
    locations: []
  };

  get isMobile() {
    return this.width > this.screenMdMin ? false : true;
  }

  openModal() {
    document.body.style.overflow = 'hidden';
    this.$modal.show('mobile-search-modal');
  }

  goToVans() {
    document.body.style.overflow = '';
    this.$modal.hide('mobile-search-modal');
    this.$router.push(createQueryFromFilterOptions(this.selectedFilter));
  }
  mounted() {
    this.width = window.innerWidth;
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
  }
}
