


























































import { Component, Vue } from 'vue-property-decorator';
import { cs } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import { SearchIcon } from 'vue-feather-icons';
import Button from '@/components/ui/Button.vue';
import store from '../store';
import { RENTAL } from '@/models/carType';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

@Component({
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    SearchIcon,
    Button
  }
})
export default class TopBannerForm extends Vue {
  public displayCustomRequest = false;

  get startDate() {
    return store.state.reservationStartDate;
  }

  private setStartDate(date: string) {
    store.dispatch('setReservationStartDate', date);
    this.updateCustomRequest();
  }

  get endDate() {
    return store.state.reservationEndDate;
  }

  private setEndDate(date: string) {
    store.dispatch('setReservationEndDate', date);
    this.updateCustomRequest();
  }

  get disabledDatesStart() {
    return store.state.reservationStartDisableDates;
  }

  get disabledDatesEnd() {
    return store.state.reservationEndDisableDates;
  }

  get cs() {
    return cs;
  }

  private updateCustomRequest() {
    this.$nextTick(() => {
      const dateDiff = moment(this.endDate).diff(moment(this.startDate), 'days');
      this.displayCustomRequest = dateDiff > 25;
    });
  }

  public searchCars() {
    this.$router.push({
      path: RENTAL,
      query: {
        from: moment(this.startDate).format(DEFAULT_DATE_FORMAT),
        to: moment(this.endDate).format(DEFAULT_DATE_FORMAT)
      }
    });
  }
}
