var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner",style:({
      backgroundColor: _vm.bannerContent.backgroundColor,
      cursor: _vm.bannerContent.link ? 'pointer' : 'unset',
      backgroundImage: ("url(" + (_vm.bannerContent.backgroundImage) + ")"),
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }),on:{"click":_vm.handleClick}},[_c('div',{staticClass:"banner__content"},[_c('div',{staticClass:"banner__text"},[_c('h3',{class:("banner__heading " + (_vm.bannerContent.keepProportionsOnMobile ? '' : 'ensmall')),style:({ color: _vm.bannerContent.fontColor }),domProps:{"innerHTML":_vm._s(_vm.bannerContent.heading)}}),(_vm.bannerContent.description)?_c('small',{class:("banner__description " + (_vm.bannerContent.keepProportionsOnMobile ? '' : 'hide-on-mobile') + " "),style:({ color: _vm.bannerContent.fontColor }),domProps:{"innerHTML":_vm._s(_vm.bannerContent.description)}}):_vm._e(),_c('p',{class:("banner__large-description" + (_vm.bannerContent.largeDescriptionMobile ? ' banner__phone-mobile' : '')),style:({ color: _vm.bannerContent.fontColor })},[_vm._v(" "+_vm._s(_vm.bannerContent.largeDescription)+" ")])]),_c('div',{staticClass:"banner__graphic"},[(_vm.bannerContent.image)?_c('span',[_c('img',{class:("banner__image " + (_vm.bannerContent.keepProportionsOnMobile ? '' : 'ensmall')),attrs:{"src":_vm.bannerContent.image,"alt":"banner image"}}),_c('div',{staticClass:"banner__image-blur"})]):(_vm.bannerContent.icon && _vm.bannerContent.icon.path && _vm.bannerContent.icon.color)?_c('span',{staticClass:"banner__icon-housing",style:({ 'background-color': _vm.bannerContent.icon.backgroundColor })},[_c('img',{staticClass:"banner__icon",style:({ color: _vm.bannerContent.icon.color }),attrs:{"src":_vm.bannerContent.icon.path,"alt":"icon"}})]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }