import { GetAllReviews, ReviewProviders } from '@/models/review';
import axios from 'axios';

class ReviewService {
  public async getReviews(provider?: keyof typeof ReviewProviders): Promise<GetAllReviews> {
    return (
      await axios.get<{ data: GetAllReviews }>('/review', {
        params: {
          provider
        }
      })
    ).data.data;
  }
}

export default new ReviewService();
