

























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import NewCustomDatePicker, { DateRange } from '../ui/calendar/NewCustomDatePicker.vue';
import TreeSelect from '../ui/form/TreeSelect.vue';
import FieldWrapper from './../ui/form/fieldWrapper.vue';
import { FilterOption } from '@/store/types';
import store from '@/store';

export interface NewVanFilterOptions {
  dates: DateRange;
  types: FilterOption[];
  locations: FilterOption[];
}

@Component({
  components: {
    NewCustomDatePicker,
    FieldWrapper,
    TreeSelect
  }
})
export default class NewVanFilter extends Vue {
  @Prop() readonly value!: NewVanFilterOptions;

  public availableVans: FilterOption[] = [];
  public availableLocations: FilterOption[] = [];
  public selectedFilter: NewVanFilterOptions = {
    dates: { startDate: null, endDate: null },
    types: [],
    locations: []
  };

  @Watch('value')
  onValueChange(newValue: NewVanFilterOptions) {
    this.selectedFilter = newValue;
  }
  @Watch('selectedFilter', { deep: true })
  onFilterChange(newFilter: NewVanFilterOptions) {
    this.$emit('input', newFilter);
  }

  // this bind works - is it useful?
  // usage same as in custom date picker
  public onClick() {}

  private async getAvailableFilters() {
    const { vehicleFilters, parkingFilters } = store.state;

    this.availableVans = vehicleFilters;
    this.availableLocations = parkingFilters;
  }

  async mounted() {
    await this.getAvailableFilters();
  }
}
