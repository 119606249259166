








import { Vue } from 'vue-property-decorator';
export default class SeznamReviewsBadge extends Vue {}
