






































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
// @ts-ignore: eslint-disable-next-line // no types
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { XIcon, CalendarIcon } from 'vue-feather-icons';

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

@Component({
  components: {
    DateRangePicker,
    XIcon,
    CalendarIcon
  }
})
export default class NewCustomDatePicker extends Vue {
  @Prop({ type: Object }) readonly value!: DateRange;
  public dates: DateRange = { startDate: null, endDate: null };
  public placeholderText = 'Vyberte termín';
  public isPlaceholderVisible = true;
  public isCalendarOpen = false;
  public tempStartDate: Date | null = null; // Temporary start date
  public localeData = {
    direction: 'ltr',
    format: 'dd/mm/yyyy',
    separator: ' - ',
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    weekLabel: 'W',
    customRangeLabel: 'Custom Range'
  };

  /**
   * Reacts to changes in the temporary start date by updating the date range.
   * If the selected start date is in a different month than currently displayed,
   * the calendar view is shifted to match the month of the new start date.
   * This ensures that the user sees the relevant month immediately after selection.
   * @param {Date | null} newVal - The newly selected or updated start date.
   */
  @Watch('tempStartDate')
  onTempStartDateChanged(newVal: Date | null) {
    if (newVal) {
      this.dateRange = { startDate: newVal, endDate: this.createNewDateInstance(newVal) };
      const currentDisplayedMonth = new Date(this.dates.startDate || new Date()).getMonth();
      const selectedMonth = newVal.getMonth();

      // Determine the direction and invoke the shift method if necessary
      if (selectedMonth !== currentDisplayedMonth) {
        const direction = selectedMonth > currentDisplayedMonth ? 'next' : 'prev';
        this.shiftCalendar(direction);
      }
    }
  }

  // Computed property for date range
  get dateRange(): DateRange {
    return this.value;
  }

  set dateRange(newValue: DateRange) {
    this.$emit('input', newValue);
  }

  // Creates a new Date instance to ensure separation of date references.
  createNewDateInstance(date: Date): Date {
    return new Date(date.getTime());
  }

  /**
   * Shifts the calendar to the next or previous month.
   * @param {'next' | 'prev'} direction - Direction to shift the calendar.
   */
  private shiftCalendar(direction: 'next' | 'prev') {
    const button: HTMLElement | null = document.querySelector(`.${direction}.available`);
    if (button) {
      button.click();
    }
  }

  public disabledPastDates(date: Date): boolean {
    const parsedDate = moment(date);
    return parsedDate.isBefore(moment().startOf('day'));
  }

  dateFormat = (classes: Record<string, boolean>, date: Date): Record<string, boolean> => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today) {
      classes.disabled = true;
    }
    return classes;
  };

  // Resets the dates to null and restores placeholder visibility
  clearDates(): void {
    this.dates = { startDate: null, endDate: null };
    this.isPlaceholderVisible = true;
  }

  // Handles the initial selection of a start date by temporarily storing it and hiding the placeholder.
  handleStartSelection(startDate: Date): void {
    this.tempStartDate = startDate;
    this.isPlaceholderVisible = false;
  }

  /**
   * Finalizes or discards the selection based on datepicker visibility.
   * @param {boolean} open - Indicates if the datepicker is open.
   */
  handleToggle(open: boolean) {
    this.isCalendarOpen = open;
    if (!open) {
      // Check if only the start date is selected and end date is not
      this.$nextTick(() => {
        if (!this.isPlaceholderVisible && !this.dates.endDate && this.tempStartDate) {
          this.dateRange = { startDate: this.tempStartDate, endDate: this.createNewDateInstance(this.tempStartDate) };
          this.tempStartDate = null; // Clear the temporary state
        }
      });
    }
  }

  @Watch('dates')
  onDateChange(newDate: DateRange) {
    this.$emit('input', newDate);
  }

  @Watch('value')
  onValueChange(newValue: DateRange) {
    this.dates = newValue;
  }
}
