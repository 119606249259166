







import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class FieldWrapper extends Vue {
  @Prop(String) readonly label!: string;
}
