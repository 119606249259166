



































import { Component, Vue } from 'vue-property-decorator';
import ReviewsEntry from './entry/index.vue';
import reviewService from '@/services/review.service';
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons';
import { GetAllReviews, ReviewProviders, ReviewWithProvider } from '@/models/review';
import GoogleReviewsBadge from './googleBadge/index.vue';
import SeznamReviewsBadge from './seznamBadge/index.vue';

@Component({
  components: {
    ReviewsEntry,
    ChevronRightIcon,
    ChevronLeftIcon,
    GoogleReviewsBadge,
    SeznamReviewsBadge
  }
})
export default class Reviews extends Vue {
  allReviews: ReviewWithProvider[] = [];
  googleAverage = 4.5;
  currentlyScrolledElement = 0;
  isLeftButtonDisabled = true;
  isRightButtonDisabled = false;
  scrolled = false;

  async mounted() {
    const getReviews = await reviewService.getReviews();
    this.allReviews = this.extractReviews(getReviews).sort(
      (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    // duplicate reviews to make it seem like infinite scroll :>
    this.allReviews = Array(5).fill(this.allReviews).flat();
    if (getReviews.google) {
      this.googleAverage = getReviews.google.averageRating;
      this.$store.dispatch('setGoogleRating', getReviews.google.averageRating);
    }
    if (window.innerWidth > 768) {
      this.currentlyScrolledElement = 1;
    }
    window.addEventListener('resize', this.handleResize);
    (this.$refs.row as HTMLElement).addEventListener('scroll', this.onscroll);
  }

  private extractReviews(getAllResponse: GetAllReviews) {
    return Object.values(ReviewProviders).flatMap((provider) => {
      const sourceReviews = getAllResponse[provider]?.reviews;

      return (
        sourceReviews?.map((review) => ({
          ...review,
          provider
        })) ?? []
      );
    });
  }
  scrollRight() {
    if (this.currentlyScrolledElement === this.allReviews.length - 1) return;
    this.currentlyScrolledElement++;
    this.scrolled = false;
    this.scrollToCurrentElement();
  }
  scrollLeft() {
    if (this.currentlyScrolledElement === 0) return;
    this.currentlyScrolledElement--;
    this.scrollToCurrentElement();
  }
  handleResize() {
    if (this.isElementInViewport(this.$refs.row as HTMLElement)) {
      this.scrollToCurrentElement();
    }
  }
  scrollToCurrentElement() {
    const reviews = document.getElementsByClassName('entry-review');
    const el = reviews[this.currentlyScrolledElement];
    if (!el) throw new Error('Review element to be scroolled to was not found');
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  }

  isElementInViewport(el: Element | HTMLElement) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  onscroll() {
    const reviews = this.$refs.row as HTMLElement;
    this.isLeftButtonDisabled = reviews.scrollLeft === 0;
    this.isRightButtonDisabled = reviews.scrollWidth - reviews.scrollLeft === reviews.clientWidth;
  }
}
