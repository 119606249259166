
















import { Component, Vue } from 'vue-property-decorator';
import { BannerContent } from '@/components/ui/banner/consts';
import Banner from '@/components/ui/banner/index.vue';
//@ts-ignore
import { yellow } from '@/styles/interface/_colors.module.scss';
import { ChevronRightIcon } from 'vue-feather-icons';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';
import moment from 'moment';

@Component({
  components: {
    Banner,
    ChevronRightIcon
  }
})
export default class CarFleet extends Vue {
  public carBanners: BannerContent[] = [
    {
      heading: 'Dodávky',
      backgroundColor: yellow,
      description: '17 vozidel',
      link: {
        path: `/dodavky?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(
          DEFAULT_DATE_FORMAT
        )}&vozidla=dodavka-m,dodavka-l`
      },
      image: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/white-truck-side-view.webp',
      keepProportionsOnMobile: true
    },
    {
      heading: 'Mikrobusy',
      backgroundColor: yellow,
      description: '1 vozidlo',
      image: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/white-microbus-side-view.webp',
      link: {
        path: `/mikrobusy?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(DEFAULT_DATE_FORMAT)}`
      },
      keepProportionsOnMobile: true
    },
    {
      heading: 'Pickupy',
      backgroundColor: yellow,
      description: '3 vozidla',
      image: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/white-van-side-view.webp',
      link: {
        path: `/pujcovna?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(
          DEFAULT_DATE_FORMAT
        )}&vozidla=dodavka-s`
      },
      keepProportionsOnMobile: true
    }
  ];
  goToVans() {
    this.$router.push(
      `/pujcovna?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(DEFAULT_DATE_FORMAT)}`
    );
  }
}
