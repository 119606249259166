import { render, staticRenderFns } from "./CarFleet.vue?vue&type=template&id=68c47b37&scoped=true&"
import script from "./CarFleet.vue?vue&type=script&lang=ts&"
export * from "./CarFleet.vue?vue&type=script&lang=ts&"
import style0 from "@/../node_modules/buefy/dist/buefy.css?vue&type=style&index=0&id=68c47b37&scoped=true&lang=css&"
import style1 from "./CarFleet.vue?vue&type=style&index=1&id=68c47b37&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68c47b37",
  null
  
)

export default component.exports