



















































import { Component, Vue } from 'vue-property-decorator';
import TopBannerForm from '@/components/topBannerForm.vue';
import store from '@/store';
import { RENTAL } from '@/models/carType';
import ClientOnly from 'vue-client-only';
import YellowCheckmarkIcon from '@/assets/icons/YellowCheckmarkIcon.vue';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';
import GoogleReviewsBadge from '@/components/reviews/googleBadge/index.vue';
import SeznamReviewsBadge from '@/components/reviews/seznamBadge/index.vue';

@Component({
  components: {
    TopBannerForm,
    ClientOnly,
    YellowCheckmarkIcon,
    GoogleReviewsBadge,
    SeznamReviewsBadge
  }
})
export default class TopBannerNew extends Vue {
  public setCityToStore(cityValue: string) {
    store.dispatch('setCityUrl', cityValue);
  }

  get rentalName() {
    return RENTAL;
  }
  get googleRating() {
    return this.$store.state.googleRating;
  }
  public navigateToRentalByCity(city?: string) {
    this.$router.push({
      path: city ? `${this.rentalName}/${city}` : this.rentalName,
      query: { from: moment().format(DEFAULT_DATE_FORMAT), to: moment().format(DEFAULT_DATE_FORMAT) }
    });
  }
}
