<template>
  <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 5.1666C24 3.45995 23.1733 2.11328 21.5933 2.11328C20.0066 2.11328 19.0466 3.45995 19.0466 5.15329C19.0466 7.15998 20.18 8.17328 21.8066 8.17328C22.5999 8.17328 23.2 7.99329 23.6533 7.73994V6.40658C23.1999 6.63326 22.68 6.77322 22.0199 6.77322C21.3733 6.77322 20.7999 6.54655 20.7266 5.75987H23.9866C23.9866 5.67325 24 5.32658 24 5.1666ZM20.7066 4.53325C20.7066 3.7799 21.1667 3.4666 21.5866 3.4666C21.9933 3.4666 22.4267 3.77995 22.4267 4.53325H20.7066Z"
      fill="#23262F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4733 2.11328C15.82 2.11328 15.3999 2.41997 15.1666 2.63329L15.0799 2.21996H13.6133V9.99332L15.28 9.63999L15.2866 7.7533C15.5266 7.92663 15.8799 8.17328 16.4666 8.17328C17.6599 8.17328 18.7466 7.21327 18.7466 5.09995C18.74 3.16662 17.64 2.11328 16.4733 2.11328ZM16.0733 6.70662C15.6799 6.70662 15.4466 6.56661 15.2866 6.39327L15.28 3.91996C15.4533 3.72661 15.6933 3.5933 16.0733 3.5933C16.68 3.5933 17.0999 4.27329 17.0999 5.14663C17.0999 6.03999 16.6866 6.70662 16.0733 6.70662Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3201 1.71921L12.9934 1.35923V0.00585938L11.3201 0.359236V1.71921Z"
      fill="black"
    />
    <path d="M12.9934 2.22656H11.3201V8.05988H12.9934V2.22656Z" fill="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.52665 2.71848L9.41997 2.22514H7.97998V8.05845H9.64665V4.10513C10.04 3.59177 10.7066 3.68514 10.9133 3.75845V2.22514C10.7 2.14517 9.91997 1.9985 9.52665 2.71848Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.1934 0.779297L4.56671 1.12597L4.56006 6.46595C4.56006 7.45263 5.30005 8.17931 6.28673 8.17931C6.83341 8.17931 7.23338 8.07932 7.4534 7.95928V6.60596C7.24008 6.69263 6.18675 6.99927 6.18675 6.01264V3.64597H7.4534V2.22599H6.18675L6.1934 0.779297Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.68668 3.91996C1.68668 3.65995 1.9 3.55997 2.25333 3.55997C2.75999 3.55997 3.39999 3.7133 3.90669 3.98666V2.41997C3.35335 2.2 2.80667 2.11328 2.25333 2.11328C0.900012 2.11328 0 2.81999 0 3.99997C0 5.83998 2.53335 5.54665 2.53335 6.33998C2.53335 6.64662 2.26669 6.74665 1.89335 6.74665C1.34001 6.74665 0.63335 6.51997 0.0733565 6.21332V7.79998C0.693348 8.06664 1.32004 8.17998 1.89335 8.17998C3.28 8.17998 4.23335 7.49329 4.23335 6.29999C4.2267 4.31332 1.68668 4.66665 1.68668 3.91996Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'StripeIcon'
};
</script>
