<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_188_3360)">
      <path
        d="M5.66663 8.47583L7.66663 10.4758L11 7.1425"
        stroke="#111111"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.33333 12.809L2.13075 4.3909C2.05685 3.87361 2.39389 3.38814 2.90398 3.27478L7.56614 2.23875C7.85189 2.17525 8.14811 2.17525 8.43386 2.23875L13.096 3.27478C13.6061 3.38814 13.9432 3.87361 13.8693 4.3909L12.6667 12.809C12.6195 13.139 12.3333 15.1423 8 15.1423C3.66667 15.1423 3.38047 13.139 3.33333 12.809Z"
        stroke="#111111"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_188_3360">
        <rect width="16" height="16" fill="white" transform="translate(0 0.809082)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SecurityCheckIcon'
};
</script>
