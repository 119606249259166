









































interface BuefyCarousel extends Vue {
  prev(): void;
  next(): void;
}

import StripeIcon from '@/assets/icons/StripeIcon.vue';
import DalnicniZnamkaIcon from '@/assets/icons/DalnicniZnamkaIcon.vue';
import PhoneIcon from '@/assets/icons/PhoneIcon.vue';
import SecurityCheckIcon from '@/assets/icons/SecurityCheckIcon.vue';
import EUIcon from '@/assets/icons/EUIcon.vue';
import { Component, Vue } from 'vue-property-decorator';
import ArrowLeftIcon from '@/assets/icons/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/assets/icons/ArrowRightIcon.vue';

@Component({
  components: {
    StripeIcon,
    DalnicniZnamkaIcon,
    PhoneIcon,
    SecurityCheckIcon,
    EUIcon,
    ArrowLeftIcon,
    ArrowRightIcon
  }
})
export default class Pros extends Vue {
  public width = 0;
  public carousel = 0;
  public animated = 'slide';
  public drag = false;
  public autoPlay = true;
  public pauseHover = true;
  public pauseInfo = false;
  public repeat = true;
  public pauseType = 'is-primary';
  public interval = 3000;

  get iconsWithText() {
    return [
      { icon: StripeIcon, text: 'Bezpečná platba online' },
      { icon: DalnicniZnamkaIcon, text: 'Dálniční známka' },
      { icon: PhoneIcon, text: 'Nonstop telefonická podpora' },
      { icon: SecurityCheckIcon, text: 'GPS sledování a zabezpečení' },
      { icon: EUIcon, text: 'Zapůjčení do zahraničí' }
    ];
  }

  mounted() {
    this.width = window.innerWidth;
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
  }

  prevSlide() {
    (this.$refs.prosCarousel as BuefyCarousel).prev();
  }

  nextSlide() {
    (this.$refs.prosCarousel as BuefyCarousel).next();
  }
}
