

























import { Component, Vue } from 'vue-property-decorator';
import parkingService from '@/services/parkingLocations.service';
import { getParkingPositionsFromParkings, LatLngForMap } from '@/components/map/locationParser';
import { BannerContent } from '@/components/ui/banner/consts';
import Banner from '@/components/ui/banner/index.vue';
//@ts-ignore
import { yellow } from '@/styles/interface/_colors.module.scss';
import { ChevronRightIcon } from 'vue-feather-icons';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';
import moment from 'moment';

@Component({
  components: {
    Banner,
    ChevronRightIcon,
    MapBox: () => (process.client ? import('@/components/map/mapBox.vue') : import('vue-client-only'))
  }
})
export default class LocationsMap extends Vue {
  public parkings: LatLngForMap[] = [];
  public parkingsLoading = true;
  public cityBanners: BannerContent[] = [
    {
      heading: 'Praha',
      backgroundColor: yellow,
      description: '17 dodávek<br>13 míst k vyzvednutí',
      link: {
        path: `/pujcovna/praha?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(DEFAULT_DATE_FORMAT)}`
      },
      keepProportionsOnMobile: true
    },
    {
      heading: 'Plzeň',
      description: '4 dodávky',
      backgroundColor: yellow,
      link: {
        path: `/pujcovna/plzen?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(DEFAULT_DATE_FORMAT)}`
      },
      keepProportionsOnMobile: true
    }
  ];
  public mapShowed = false;

  async mounted() {
    this.parkingsLoading = true;
    const parkings = await parkingService.getParkingLocations();
    this.parkings = getParkingPositionsFromParkings(parkings, undefined, (parking) => ({
      permanentTooltip: false,
      tooltip: parking.locationDescription
    }));
    this.parkingsLoading = false;
  }

  public handleChangeMapInViewport(visible: boolean) {
    if (visible && !this.mapShowed) {
      this.mapShowed = true;
    }
  }

  goToVans() {
    this.$router.push(
      `/pujcovna?from=${moment().format(DEFAULT_DATE_FORMAT)}&to=${moment().format(DEFAULT_DATE_FORMAT)}`
    );
  }
}
