<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="14" fill="#FFC125" />
    <path
      d="M19.4001 10.3999C19.1189 10.681 12.6904 17.108 12.4092 17.3891C12.128 17.6702 11.6722 17.6702 11.391 17.3891C11.1098 17.108 8.88137 14.8809 8.6001 14.5999"
      stroke="#111111"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'YellowCheckmarkIcon'
};
</script>
