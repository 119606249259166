





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BannerContent } from './consts';

@Component({})
export default class Banner extends Vue {
  @Prop({ required: true }) bannerContent!: BannerContent;

  handleClick() {
    if (this.bannerContent.link?.path) {
      this.$router.push({
        path: this.bannerContent.link.path,
        hash: '#vyzvednuti'
      });
    } else if (this.bannerContent.link?.uri) {
      window.location.href = this.bannerContent.link.uri;
    }
  }
  isKakadooPhoneNumber(text: string) {
    return text.includes('776 500 507');
  }
}
