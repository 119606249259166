


















import { Component, Vue } from 'vue-property-decorator';
import TopBannerNew from '@/components/topBannerNew.vue';
import Button from '@/components/ui/Button.vue';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';
import ClientOnly from 'vue-client-only';
import { RENTAL } from '@/models/carType';
import moment from 'moment';
import parkingService from '@/services/parkingLocations.service';
import { getParkingPositionsFromParkings, LatLngForMap } from '@/components/map/locationParser';
import { AlertCircleIcon } from 'vue-feather-icons';
import HowItWorksInFiveSteps from '@/components/homepage/HowItWorksInFiveSteps.vue';
import WhyKakadoo from '@/components/homepage/WhyKakadoo.vue';
import Reviews from '@/components/reviews/index.vue';
import TodayAvailableCars from '@/components/homepage/TodayAvailableCars.vue';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';
import Pros from '@/components/homepage/Pros.vue';
import HomepageFilter from '@/components/homepage/HomepageFilter.vue';
import LocationsMap from '@/components/homepage/LocationsMap.vue';
import CarFleet from '@/components/homepage/CarFleet.vue';
@Component({
  components: {
    TopBannerNew,
    Button,
    MapBox: () => (process.client ? import('@/components/map/mapBox.vue') : import('vue-client-only')),
    ClientOnly,
    AlertCircleIcon,
    HowItWorksInFiveSteps,
    Pros,
    HomepageFilter,
    TodayAvailableCars,
    LocationsMap,
    CarFleet,
    Reviews,
    WhyKakadoo
  },
  metaInfo: metaInfo({
    title: 'Kakadoo - Online půjčovna dodávek',
    url: buildUrl('/')
  })
})
export default class Home extends Vue {
  public mapShowed = false;
  public parkings: LatLngForMap[] = [];
  public parkingsLoading = true;
  public isNewHomepageVisible = true;

  goToVans() {
    this.$router.push({
      path: RENTAL,
      query: {
        from: moment().format(DEFAULT_DATE_FORMAT),
        to: moment().format(DEFAULT_DATE_FORMAT)
      }
    });
  }

  handleChangeMapInViewport(visible: boolean) {
    if (visible && !this.mapShowed) {
      this.mapShowed = true;
    }
  }

  async mounted() {
    // this.isNewHomepageVisible =
    //   (await featureFlagService.getFeatureFlagActiveByName(FeatureFlag.USE_NEW_HOMEPAGE)) ?? false;
    this.parkingsLoading = true;
    const parkings = await parkingService.getParkingLocations();
    this.parkings = getParkingPositionsFromParkings(parkings, undefined, (parking) => ({
      permanentTooltip: true,
      tooltip: parking.locationDescription
    }));
    this.parkingsLoading = false;
  }
}
