import { RawLocation } from 'vue-router';
import moment from 'moment';
import { NewVanFilterOptions } from '../newVanFilter.vue';
import { RENTAL } from '@/models/carType';
import { FilterOption } from '@/store/types';
import store from '@/store';

const fullSectionSelected = (selected: FilterOption, available: FilterOption[]): boolean => {
  const fullSectionSelected = available.find((e) => e.parentPathSlug === selected.parentPathSlug);
  if (selected.subValues?.length === fullSectionSelected?.subValues?.length) return true;
  return false;
};

export const createQueryFromFilterOptions = (filter: NewVanFilterOptions): RawLocation => {
  const { vehicleFilters, parkingFilters } = store.state;

  let path = RENTAL;
  let typePath = RENTAL;
  let selectedTypes;
  let selectedLocations;

  if (filter.types.length === 1 && fullSectionSelected(filter.types[0], vehicleFilters)) {
    typePath = filter.types[0].parentPathSlug;
    path = typePath;
  } else {
    selectedTypes = filter.types.map((e) => e.subValues?.map((sub) => sub.slug)).join(',');
  }

  if (filter.locations.length === 1 && fullSectionSelected(filter.locations[0], parkingFilters)) {
    path = `${typePath}/${filter.locations[0].parentPathSlug}`;
  } else {
    selectedLocations = filter.locations.map((e) => e.subValues?.map((sub) => sub.slug)).join(',');
  }

  return {
    path,
    query: {
      // moment won't create a date from null but creates today from undefined
      from: moment(filter?.dates?.startDate || undefined).format('YYYY-MM-DD'),
      to: moment(filter?.dates?.endDate || undefined).format('YYYY-MM-DD'),
      vozidla: selectedTypes || undefined,
      parkoviste: selectedLocations || undefined
    }
  };
};
